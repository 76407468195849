.SingleProduct .slideritems {
    background: #F4F4F4;
    padding: 60px;
    border-radius: 20px;
    box-shadow: 1px 3px 7px #888888;
    margin-top: 10px;
    min-height: auto !important;
    max-height: auto !important;
    position: relative;
}

/* .SingleProduct .Specification
{
    margin-top: 50px;
} */
.SingleProduct .liters
{
    margin-top: 20px;
}
.ProductInfoBtn
{
    margin: 50px 0px;
}
.ProductInfoBtn
{
    display: grid;
    column-gap: 10px;
    grid-template-columns: auto auto auto auto;
}

.HowToUse
{
    background: #F4F4F4;
    box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 50px;
}
.HowToUse ul
{
    list-style: auto;
    margin-top: 30px;
    padding-left: 30px;
}
.HowToUse ul li::marker
{
    font-size: 2rem;
}
.HowToUse table
{
    width: 100%;
}
.HowToUse table tr
{
    border-bottom:1px solid black;
}
.HowToUse table tr td
{
    padding: 10px 10px;
}
.HowToUse table  tr:nth-child(even) {
    background: #dfdfdf;
  }

.ProductInfoBtn button {
    color: black;
    background: #F4F4F4;
    font-size: 1.8rem;
    font-weight: 300;
    font-family: Titillium Web,sans-serif;
    border: none;
    padding: 10px 15px;
    margin: 0;
    border-radius: 10px;
    width: 100%;
  }
  
  .ProductInfoBtn button.active {
    background-color: #273889;
    color: white;
  }

@media screen and (max-width: 575.5px) 
{
    .ProductDetail .SingleProduct {
        padding: 0% 4% !important;
    }
    .SingleProduct .slideritems {
        padding: 15px;
        display: block !important;
    }
    .ProductInfoBtn button 
    {
        font-size: 1.5rem !important; 
    }
    .ProductInfoBtn
{
    row-gap: 10px;
    grid-template-columns: auto auto;
}
.HowToUse {
    padding: 12px;
}
.HowToUse p:nth-child(2)
{
    margin-left: 0px !important;
}
.HowToUse table tr td:nth-child(2)
{
    border-left: 1px solid black;
    border-right:1px solid black;
}
}

