.NavbarButtons a
{
    text-decoration: none;
    margin-bottom: 5px;
    display: block;
    white-space: nowrap;
}
.Mycontainer .NavbarButtons a
{
    margin-bottom: 15px;
}