.EachProduct .slideritems {
    background: #F4F4F4;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 1px 3px 7px #888888;
    margin-top: 10px;
    width: 100%;
    min-height: 45vh;
    max-height: 45vh;
    position: relative;
    align-items: center;
}

.EachProduct .sliderimage
{
    padding-right:20px;
    width: 85%;
}

.productdetail p:nth-child(4) span
{
    display:none;
}


   /* Styles for laptops and desktops */
   @media screen and (min-width: 1801px) {
    /* Styles for laptops and desktops go here */
  
    .EachProduct .sliderimage
    {
        width: 58% !important;
    }
    .EachProduct .slideritems {
        min-height: 54vh;
        max-height: 54vh;
    }
    .Gobutton a i svg {
        width: 30px;
        height: 32px;
    }
  }