.ContactUS
{
    margin-top:50px;
}
.ContactUS .DetailsSec .leftSec p:nth-child(3) {
    font-weight: 600 !important;
    display: inline-block;
    margin-left: 0px;
}
.ContactUS .DetailsSec .leftSec p:nth-child(1){
    color: #273889;
}
.ContactUS .verticalBorder
 {
    border-left: 2px solid #919191;
    height: 16vh;
}

.ContactUS .DetailsSec .leftSec .border
{
    background: #919191;
    height: 2px;
    border: none !important;
}
.ContactUS,.WriteUs,.ContactForm
{
    padding: 0% 8%;
}
.WriteUs
{
    background-image: url(../../public/ContactWrite.webp)
}
.ContactForm .formData
{
    display: grid;
  grid-template-areas:
  'name email'
  'message  message';
  grid-gap: 80px;
  padding: 10px;
  margin-top: 50px;
}
.username,.useremail,.usermessage
{
    width: 100%;
    border-bottom: 1px solid #C2C2C2;
    padding-bottom: 5px;
}
.username label,.useremail label,.usermessage label
{
    display: block !important;
    margin-left: 0px;
    text-transform: uppercase;
    font-weight: 500;
}
.username input,.useremail input,.usermessage textarea
{
    background: transparent;
    border: 0;
    width: 100%;
    font-size: 2rem;
}
.usermessage textarea
{
    resize: none;
}
.username input:focus-visible,.useremail input:focus-visible,.usermessage textarea:focus-visible
{
    outline: none !important;
}
.username input::placeholder,.useremail input::placeholder,.usermessage textarea::placeholder
{
    font-size: 1.3rem !important;
    color: #C2C2C2;
}
.username { grid-area: name; }
.useremail { grid-area: email; }
.usermessage { grid-area: message; }

.thankyou_message {
    display: none;
    border-radius: 40px;
    padding: 0px 15px 0px;
    color: white;
    width: 40%;
    font-family: 'Titillium Web', sans-serif;
    justify-content: center;
    align-items: center;
    position: absolute;
    border: 1px solid white;
    bottom: 0px;
    background: white;
    justify-content: center;
    align-items: center;
}

.press-effect {
    padding: 5px;
    font-size: 14px;
    cursor: pointer;
    min-width: 120px;
    margin: 15px 5px;
    min-height: 20px;
    text-decoration: none;
    border-bottom: 4px solid #d9d9d9;
    box-shadow: 0px 5px 10px #333;
    -webkit-transition: all 0.3s;
    transition: all 0.4s;

    color: white;
    background: #273889;
    font-size: 2rem;
    font-weight: 300;
    font-family: Titillium Web,sans-serif;
    border: none;
    padding: 10px 30px 10px 30px;
    margin: 50px 0px 0px 0px;
    border-radius: 0;
    width: auto;
    text-transform: auto;
    white-space: nowrap;
  }
  
  .press-effect:active {
    box-shadow: 0px 4px 8px #333;
    -webkit-transform: scale(0.78);
    transform: scale(0.78);
  }
  .ContactUs .Footer,
  .ContactUs .Footer .footerTop
  {
    margin-top:0px !important;
  }
  .mapsection
  {
    margin-top:85px;
  }
  .mapsection iframe
  {
    width:100% !important;
  }

@media screen and (max-width: 575.5px) 
{
    .ContactForm .formData {
        display: grid;
        grid-template-areas:
            'name name'
            'email email'
            'message message';
        grid-gap: 10px;
    }
}