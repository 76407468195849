
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');



h1,h2,h3,h4,h5,h6,p,a,select,input,label,span
{
  font-family: 'Inter', sans-serif;
}
html
{
    /* font-size: 10px !important; */
    
        -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
           -khtml-user-select: none; /* Konqueror HTML */
             -moz-user-select: none; /* Old versions of Firefox */
              -ms-user-select: none; /* Internet Explorer/Edge */
                  user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
      
}


body
{
  margin: 0;
  padding: 0;
}
a
{
  text-decoration:none !important;
}
  
  /* Base styles for all devices */
  
  /* Styles for mobile */
  @media (max-width: 350px)  {
    /* Styles for mobile go here */
  
    html{
      font-size: 40.0%;
    }
  }
  /* Styles for mobile */
  @media (min-width: 350px)  {
    /* Styles for mobile go here */
  
    html{
      font-size: 48.0%;
    }
  }
  
  /* Styles for small tablets */
  @media screen and (min-width: 768px) {
    /* Styles for small tablets go here */
    
    html{
      font-size: 50.0%;
    }
  }
  
  /* Styles for tablets */
  @media screen and (min-width: 1024px) {
    /* Styles for tablets go here */
  
    html{
      font-size: 55.0%;
    }
  }
  
  /* Styles for laptops and desktops */
  @media screen and (min-width: 1400px) {
    /* Styles for laptops and desktops go here */
  
    html{ 
      font-size: 62.5%;  
    } 
  }
  /* Styles for laptops and desktops */
  @media screen and (min-width: 1920px) {
    /* Styles for laptops and desktops go here */
  
    html{
      font-size: 72.5%;
    } 
  }
  
  /* Styles for large screens */
  @media screen and (min-width: 2560px) {
    /* Styles for large screens go here */
  
    html{
      font-size: 76.5%;
    }
  }

  body{
    background-color: rgb(0, 0, 0);
}