.preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #fbfbfb;
  }
  
  .preloader img
  {
    width: 80%;
  }