.searchDiv 
{
    margin-bottom:8px;
    position: relative;
    
}
.searchSec
{
    display:flex;
}
.searchDiv input
{
    width: 12vw;
    height: 4.5vh;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid black;
    font-weight:600;
    font-size:1.6rem;
    padding:10px;
}
.searchDiv input::placeholder
{
    font-size:1.2rem;
    color:#d0d0d0;
    font-weight:100;
}
.searchDiv i
{
    background: #273889;
    cursor: pointer;
    font-size: 1.6rem;
    color: white;
    height: 4.5vh;
    padding:10px;
    border-radius: 5px;
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
}
.searchDiv i svg
{
    fill: white;
    width: 15px;
}
.suggestionContainer
{
    position: absolute;
    font-size: 1.2rem;
    font-weight: 700;
    top: 32px;
}
.suggestionContainer div
{
    background-color: white;
    margin: 3px 0px;
    padding: 2px 55px
}