/* Wide RAnge Text */

.DetailsSec .leftSec
{
    padding-top: 15vh;
    padding-bottom: 15vh;
}
.DetailsSec .leftSec p:nth-child(1)
{
 font-weight: 300 !important;
 font-size: 3rem;
}
.DetailsSec .leftSec p:nth-child(2)
{
 font-weight: 100 !important;
 font-size: 4rem;
 display: contents;
}
.DetailsSec .leftSec p:nth-child(3)
{
 font-weight: 600 !important;
 display: inline-block;
 margin-left: 10px;  
 font-size: 4rem;                
}
.DetailsSec .leftSec .border
{
    width: 25%;
    margin-top: 35px;
}

.verticalBorder
{
    border-left: 1px solid #FFFFFF;
    height: 16vh;
}