.ProductDetail .SingleProduct
{
    padding: 0% 10%;
}
.breadcrumb
{
    margin: 50px 0px;
    border-bottom: 3px solid #273889;
}
.ProductDetail
{
    margin-top: 50px;
}
.ProductDiscription .bestSellerSlider
{
    padding-left: 10%;
}
/* .ProductDetail.sae .sliderimage #Pic1 img
{
    width:92% !important;
} */
.ProductDetail.cvt .sliderimage #Pic1 img
{
    width:78% !important;
}
.ProductDiscription .bestSellerSlider .EachProduct .sliderimage {
    width: 85% !important;
}