.NavbarMAin::before
{
    background-color:#F4F4F4;
}
.SizeFilter input[type="checkbox"] {
    width: 20px !important;
    height: 20px !important;
    margin: 15px 0px;
    
  }
  .OneLT
  {
    white-space: nowrap;
  }
label
{
    font-size: 2rem;
    margin-left: 15px;
    white-space: nowrap;
}

/* .abc:after {
    content: "";
    width: 20px;
    height: 20px;
    display: block;
    border: 3px solid #A9A9A9;
    border-radius: 0px;
} */

.FilerSideBar
{
    background-color: #F4F4F4;
    width: 320px;
    padding: 5% 3%;
    height: fit-content;
}
.SizeFilter
{
    margin-top: 25px;
    text-align: left;
}
.ProductsList
{
    padding: 0% 2%;
}
#gearoil,#diesel,#gasoline,.AllOils,.Compatable,#Transmissionoil,#Flushoil,#Hydraulicoil
{
    padding-top: 10%;
}
#gearoil .ProductHeding,
#diesel .ProductHeding,#gasoline .ProductHeding,
.AllOils .ProductHeding,.Compatable .ProductHeding,
#Transmissionoil .ProductHeding,
#Flushoil .ProductHeding
#Hydraulicoil .ProductHeding
{
    border-bottom: 2px solid #D6D6D6;
    padding-bottom: 5px;
    margin-bottom: 35px;
}
.sae90 .EachProduct .sliderimage img
{
    width: 10vw !important;
}

#Transmissionoil .EachProduct .sliderimage,
#diesel .EachProduct .sliderimage,
#Flushoil .EachProduct .sliderimage,
#dieselcheck .EachProduct .sliderimage
#Hydraulicoil .EachProduct .sliderimage
{
    width: 20% !important;
}

.ProductItems
{
display: grid;
  column-gap: 35px;
  grid-template-columns: auto;
  row-gap: 35px;
}


.Gobutton 
{
    position:absolute;
    bottom: 10px;
    right: 19px;
    padding:4px 4px;
    border-radius:2px;
}
.Gobutton:hover
{
    background:#273889;
    outline:2px solid white;
    
}
.Gobutton a i
{
    font-size:1.8rem;
    color:#273889;
    padding:3px 10px;   
    display:flex; 
}
.Gobutton a i svg
{
    width: 16px;
    height: 20px;
}
.Gobutton:hover i
{
    color:white;
    outline:2px solid white;
    
}
.Gobutton:hover i svg
{
    fill:white;
}

.SingleProduct .sliderimage
{
    width: 450px;
    height: 450px;
    margin-right: 80px;
}

@media screen and (max-width: 1440px) 
{
    .SingleProduct .sliderimage
{
    width: 400px;
    height: 400px;
    margin-right: 80px;
}
.SingleProduct .sliderimage img
{
    width: 250px !important;
}
}
@media screen and (max-width: 1200px) 
{
    .SingleProduct .sliderimage
{
    width: 290px;
    height: auto !important;
    margin-right: 80px;
}
.SingleProduct .sliderimage img
{
    width: 250px !important;
}
}

@media screen and (max-width: 992px) 
{
    .SingleProduct .sliderimage
{
    width: 200px;
    height: auto !important;
    margin-right: 80px;
}
.SingleProduct .sliderimage img
{
    width: 180px !important;
}
.ProductPage .ProductDetailList {
    display: unset !important;
}
.SizeCatFilter {
    display: flex;
    justify-content: space-around;
}
.FilerSideBar {
    background-color: #F4F4F4;
    width: 445px;
}
}

@media screen and (max-width: 767.5px) 
{
    .ProductItems {
        grid-template-columns: auto !important;
    }
    .EachProduct .sliderimage {
        padding-right: 20px;
        width: 48%;
    }
    .SingleProduct .slideritems {
        padding: 15px;
        display: block !important;
    }

}

/* Media Queries for extra small Devices */

@media screen and (max-width: 575.5px) 
{
    .ProductPage .ProductDetailList
    {
        display: unset !important;
    }
    .FilerSideBar
    {
        text-align: center;
        width: 100%;
    }
    .SizeCatFilter
    {
        display: flex;
        justify-content: space-around;
    }
    .SizeFilter input[type="checkbox"] {
        margin: 10px 0px;
    }
    .ProductItems {
            padding: 0% 5% 0% 5%;
    }
    .EachProduct .slideritems
    {
        display: block !important;
        min-height:425px !important; 
        max-height:425px !important;
    }
    .sliderimage
    {
        display:flex;
        justify-content:center;
        margin-bottom:25px;
    }
    .sliderimage img
    {
        width:50%;
    }
    #Transmissionoil .EachProduct .sliderimage, #diesel .EachProduct .sliderimage, #Flushoil .EachProduct .sliderimage, #dieselcheck .EachProduct .sliderimage {
        width: 100% !important;
    }
    .sae90 .EachProduct .sliderimage img {
        width: 50% !important;
    }
}

/* Styles for laptops and desktops */
@media screen and (min-width: 1801px) {
    .Gobutton a i svg {
        width: 30px;
        height: 32px;
    }
    .FilerSideBar {
        background-color: #F4F4F4;
        width: 368px;
    }
  }