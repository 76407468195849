.LandinNavbarWraper {
  display: flex;
  justify-content: center;
  width: 100%;
}
.AboutUsNAv,
.ProductPAgeNAvbar,
.productDiscriptionNav
{
  display: flex;
}

.NavbarMAin {

  position: relative;
  padding: 10px 60px;
  display: flex;
  justify-content: space-between;
  width: 88%;
  align-items: center;
}

.NavbarMAin::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  transform: skew(20deg);
  background: white;
  z-index: 1;
  background-color: #F4F4F4;
  z-index: 2;
}

.NavbarMAin .NavbarItemsSec,
.NavbarMAin .SearchIconSec,
.NavbarMAin img {
  z-index: 2;
}

.NavbarMAin .NavbarItemsSec {
  display: flex;
}

.NavbarMAin .ClubStoreButton button {
  position: relative;
  z-index: 2;
}

.NavbarMAin .ClubStoreButton a {
  display: block;
}

.items {
  margin: 0px 20px;

}

.items p:nth-child(1) {
  display: inline;
  margin-right: 10px;
}

.items button {
  width: auto;
}

.items:hover .ItemBorder {
  visibility: visible;
  margin-top: 5px;
}

.items .ItemBorder {
  visibility: hidden;
  position: relative;
  width: 90%;
  background: #273889;
}

.items .ItemBorder::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  transform: skew(-30deg);
  background: #273889;
  color: #273889;
}


.menu-items {
  text-align: center;
  position: absolute;
  padding: 5rem;
  top: 0rem;
  left: 0rem;
  list-style: none;
  opacity: 0;
  font-size: 2rem;
  background-color: black;
  border-radius: 0.25em;
  transform: translateX(-78%);
  transition: transform 500ms ease-in-out, opacity 200ms;
  width: 100%;
  height: 100vh;
  display: n;
}
.menu-items li:nth-child(1)
{
  margin-top: 25vh;
}



.check {
  position: absolute;
  top: 5rem;
  right: 12rem;
  height: 3rem;
  width: 3rem;
  opacity: 0;
  z-index: 3;
}

.check:checked~.menu-items {
  display: block;
  transform: translateX(0%);
  opacity: 1;
}

.ham-menu { 
    height: 30px;
    width: 38px;
    position: absolute;
    top: 5rem;
    right: 12rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #a9a9a9;
    border-radius: 5px;
    align-items: center;
}
.check:checked~.ham-menu
{
  padding-left: 5px;
}

.ham-menu .line {
  background-color: white;
  border-radius: 1em;
  width: 20px;
  height: 1.8px;
}

.ham-menu .line2 {
  height: 1.8px;
}

.ham-menu .line1 {
  transform-origin: 0% 0%;
  transition: transform 100ms ease-in-out;
}

.ham-menu .line3 {
  transform-origin: 0% 100%;
  transition: transform 100ms ease-in-out;
}

/* .check:checked~.ham-menu {
  padding-left: 3px;
} */
.check:checked~.ham-menu .line {
  width: 23px;
}

.check:checked~.ham-menu .line1 {
  display: block;
  transform: rotate(45deg);
}

.check:checked~.ham-menu .line2 {
  opacity: 0;
}

.check:checked~.ham-menu .line3 {
  display: block;
  transform: rotate(-45deg);
}

.menu-items li .NavbarButtons p {
  color: white;
  font-size: 3.5rem;
}

.Mycontainer {
  z-index: 3;
  display: none;
}

@media screen and (max-width: 1440px) {
  .searchDiv input {
    width: 160px !important;
  }
}

@media screen and (max-width: 1024px) {

  .items {
    margin: 0px 2px;
  }
}


@media screen and (max-width: 800px) {
  .NavbarMAin {
    padding: 10px 28px;
  }
}

@media screen and (max-width: 768px) {

  .NavbarMAin .NavbarItemsSec,
  .SearchIconSec {
    display: none;
  }

  .Mycontainer {
    z-index: 3;
    display: block;
  }

  .searchDiv {
    justify-content: center;
  }
  .searchDiv i
  {
    position: inherit !important;
    margin-left: 0px;
  }
.searchDiv {
  display: flex;
}
.suggestionContainer 
{
  top:32px;
}


}